import * as DataLayerBasket from '../modules/dataLayer/dataLayerBasket';

export default function Navigation() {
    toggleLanguageFilter()
    toggleBasket()
    headerBasketRemove();
}

function toggleLanguageFilter() {
  var $languageFilter = $('.language-filter');
  
  $(document).on('click', function () {
  $languageFilter.removeClass('language-active');
  document.querySelector('.language-dropdown').classList.remove('language-filter-active');
});

  $languageFilter.on("click", function (e) {
    e.stopPropagation();
    $(this).toggleClass('language-active');
    document.querySelector('.language-dropdown').classList.toggle('language-filter-active');
  });
}

function toggleBasket() {
    $(document).on('click', '.shopping-basket, .basket-close', function (e) {
        e.stopPropagation();

        if (parseInt($('.shopping-basket').data("qty")) > 0 || $('.shopping-basket').hasClass("basket-active")) {

            if (parseInt($('.shopping-basket').data("qty")) > 0) {
                document.getElementById("update-headerbasket-form").addEventListener("submit", function (e) {
                    e.preventDefault();
                    $('.quantity input[type=submit]').attr("disabled", true);
                    var formData = $(this)[0];
                    $.ajax({
                        url: $(this).prop('action'),
                        type: "POST",
                        processData: false,
                        contentType: false,
                        enctype: 'multipart/form-data',
                        data: new FormData(formData),
                        dataType: "json",
                        success: function (data) {
                            if (data.response == "success") {

                                var $basketTrigger = $(".header-icons .shopping-basket");
                                $basketTrigger.find(".items").html(data.orderQty);
                                $basketTrigger.data("qty", data.orderQty);

                                $('.basket-vat.cost').text(data.orderPrice.Tax);
                                $('.order-subtotal').text(data.orderPrice.WithTax);
                                $('.basket-total .basket-total-total').text(data.orderPrice.WithTax);

                                var itemCount = $basketTrigger.find(".items").html();

                                if (itemCount == "0") {
                                    $basketTrigger.find(".items").hide();
                                }
                                else {
                                    $basketTrigger.find(".items").show();
                                }

                                $('.quantity input[type=submit]').attr("disabled", false);
                            }
                        },
                        error: function (xhr) {
                            console.log(xhr);
                        }
                    });
                });
			}
               
			
            
            $('#header-basket').slideToggle();
            document.querySelector('.shopping-basket').classList.toggle('basket-active');
            $("body").toggleClass("overlay-open");
        }
        else {
            var basketUrl = $('.shopping-basket').data("url");
            if (window.location.href.indexOf(basketUrl) > -1) {
                return false;
            }
            else {
                window.location.replace(basketUrl);
            }
        }
    });
}

function headerBasketRemove() {
    $(document).on("click", "header .basket-item-remove a", function (e) {
        e.preventDefault()

        var unitPrice = parseFloat($(this).data("price")) / parseInt($(this).data("quantity"));
        var getVariant = document.querySelector(".basket-item-name").innerHTML.split(':').map(item => item.trim());
        var variant = document.querySelector(".basket-item-name").innerHTML != null ? getVariant[1] : "";

        var product = {
            item_id: $(this).data("sku"),
            item_name: $(this).data("name"),
            affiliation: "Riobel",
            index: 0,
            currency: $(this).data("currency"),
            item_brand: "Riobel | " + $(this).data("category"),
            item_category: $(this).data("category"),
            item_variant: variant,
            price: unitPrice,
            quantity: parseInt($(this).data("quantity"))
        };

        $.ajax({
            url: "/umbraco/Surface/Basket/Remove",
            type: "POST",
            dataType: "json",
            data: { orderLineId: $(this).data("lid"), nodeId: $(this).data("nid") },
            success: function (data) {
                if (data.response == "success") {

                    DataLayerBasket.RemoveFromBasket(product);
                   
                    //Update and display header basket
                    $("header .shopping-basket").find(".items").html(data.orderQty)
                    $('.shopping-basket').data("qty", data.orderQty)
                    $("header .basket-wrapper").replaceWith(data.headerBasket);
                    $("header .basket-wrapper .basket-error").html(data.validationMessage);

                    if ($(".overlay-open").length < 1) {
                        $('.basket').slideUp();
                        $('body').removeClass("overlay-open");
                    }

                    var $basketTrigger = $(".header-icons .shopping-basket");
                    var itemCount = $basketTrigger.find(".items").html();

                    if (itemCount == "0") {
                        $basketTrigger.find(".items").hide();
                    }
                    else {
                        $basketTrigger.find(".items").show();
                    }
                }
                else {
                    $("header .basket-wrapper .basket-error").html(data.validationMessage);
                }
            },
            error: function (xhr) {
                console.log(xhr);
            }
        });
    });
}
