//import $ from "jquery";
import FAQ from './modules/faq';
import Tabs from './modules/tabs';
import Filter from './modules/filter';
import DownloadFiles from "./modules/DownloadFiles"
import Animations from './modules/animations';
import CollectionCarousel from './modules/collection-carousel';
import NewsletterForm from './modules/newsletter-form';
import Navigation from './modules/navigation';
import Select from './modules/select';
import Form from './modules/form';
import ProductDetails from './pages/product-details';
import Basket from './modules/basket';
import InputNumber from './modules/input-number';
import Pagination from './modules/pagination';
import Checkout from './pages/checkout';
import ProductCategory from './pages/productCategory';
import FilterForms from './modules/filterForm';
import Lightbox from './modules/lightbox';

require('fslightbox');
require('magnific-Popup');

 
FAQ();
Tabs();
DownloadFiles();
Animations();
CollectionCarousel();
NewsletterForm();
Navigation();
Filter();
Select(); 
Form();
ProductDetails();
Basket();
InputNumber();
Pagination();
Checkout();
FilterForms();
ProductCategory();
Lightbox();

var searchButton = document.getElementsByClassName("search-icon")[0];


searchButton.addEventListener("click", function(evt) {

    var container = document.getElementsByClassName("search-panel")[0];

        if(!container.classList.contains('active')){
            container.classList.add('active');
            evt.preventDefault();
        }
        else if(container.classList.contains('active')){
            container.classList.remove('active');          
        }
}, false);


var searchClose = document.getElementsByClassName("search-close")[0];

searchClose.addEventListener("click", function() {


    var searchContainer = document.getElementsByClassName("search-panel")[0];
    var input = document.getElementById("st");

    searchContainer.classList.remove('active');
    input.value = '';
        
}, false);


var mobileTrigger = document.getElementsByClassName("mobile-menu-trigger")[0];

mobileTrigger.addEventListener("click", function() {

mobileTrigger.classList.toggle('active');
    document.querySelector('.menu-icon').classList.toggle('menu-icon-active');
    document.querySelector('.upper-nav').classList.toggle('menu-open');
    document.querySelector('.order-summary-wrapper').classList.toggle('close-summary');
        
}, false);

document.querySelectorAll('.show-child').forEach(item => {
  item.addEventListener('click', function() {
    //handle click
    this.classList.toggle('active');
    this.nextElementSibling.classList.toggle('active');
  })
});

$(".quantity [type='number']").keydown(function (e) {
    var evt = e || window.event;
    if (evt) {
        var keyCode = evt.charCode || evt.keyCode;
        if (keyCode === 8 || keyCode === 46) {
            if (evt.preventDefault) {
                evt.preventDefault();
            } else {
                evt.returnValue = false;
            }
        }
    }
});


